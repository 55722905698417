import React, { Fragment, useEffect, useRef, useState } from 'react';
import './livelocation.css'
import { styled } from '@mui/material/styles';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Button, Card, CardContent, CardMedia, Slider, Stack, TextField, Typography } from '@mui/material';

// import maplibregl from 'maplibre-gl'; // or "const maplibregl = require('maplibre-gl');"
import * as turf from '@turf/turf'
import liff from "@line/liff";
import HTTPService from "../services/httpService";
const luxon = require('luxon')


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function LiveLocation() {

    const map = useRef(null)
    const geolocate = useRef(null)
    const [trackLocationUI, setTrackLocationUI] = useState({
        label: 'START',
        color: '',
        distance: 0,
        sent: 0
    });
    const [toggleSymbol, setToggleSymbol] = useState("→");
    const [coords, setCoords] = useState({});
    const [updateInterval, setUpdateInterval] = useState(5000);
    const [value, setValue] = useState(1);
    const [lastSaved, setLastSaved] = useState(0);
    const [lastDetected, setLastDetected] = useState(0);
    const [profile, setProfile] = useState({
        dummy: true,
        userId: 'U79bd13e9496f7310b2a82e59fa4435da',
        displayName: 'USER',
        pictureUrl: 'https://sprofile.line-scdn.net/0hUaMa7IzrCk5gGCBiq-10MRBICSRDaVNcGXhNfFUYB34IeB9NHipDL1wfACsIe0lLSHwSeFxPAytsC30ofk72emcoVHlZLk0aSH1CrQ'
    });
    // var trackLocationLabel = useCallback(() => {

    //     if (geolocate && geolocate.current && geolocate.current.options.trackUserLocation) return 'STOP'
    //     return 'START'

    // }, [geolocate])

    // useEffect(() => {
    //     console.log(geolocate);
    // }, [geolocate]);


    function valueLabelFormat(value) {
        const units = ['วินาที', 'นาที', 'ชั่วโมง'];

        let unitIndex = 0;
        let scaledValue = value;
        let fragment = 0

        while (scaledValue >= 60 && unitIndex < units.length - 1) {
            unitIndex += 1;
            scaledValue = Math.floor(scaledValue /60).toFixed(0);
            fragment = value % 60
            
        }

        return `${scaledValue}${fragment !== 0 ? ':'+fragment : ''} ${units[unitIndex]}`;
    }

    function calculateValue(value) {
        return value * 5;
    }

    function createGeolocate() {
        geolocate.current = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        });
    }

    useEffect(() => {
        if (profile.dummy) {
            if (!window.location.hostname.includes('localhost')) {
                liff
                .init({
                    liffId: '1656650625-MyQVbal7',
                    withLoginOnExternalBrowser: true
                })
                .then(async () => {
                    // setMessage("LIFF init succeeded.");
                    let profile = await liff.getProfile()
                    // console.log('liff profile', profile);
                    setProfile({
                        ...profile,
                        dummy: false
                    })
                    return
                })
                .catch((e) => {
                    // setMessage("LIFF init failed.");
                    // setError(`${e}`);
                });
            } else {

                setProfile({
                    userId: 'U79bd13e9496f7310b2a82e59fa4435da',
                    displayName: 'Chaloemphol_LOCAL',
                    pictureUrl: 'https://sprofile.line-scdn.net/0hUaMa7IzrCk5gGCBiq-10MRBICSRDaVNcGXhNfFUYB34IeB9NHipDL1wfACsIe0lLSHwSeFxPAytsC30ofk72emcoVHlZLk0aSH1CrQ',
                    dummy: false
                })

                // console.log('profile in useEffect', profile);

            }

        }
      }, [profile]);

   useEffect(() => {

    if (map.current) return
    mapboxgl.accessToken = 'pk.eyJ1IjoiY2hhbG9lbXBob2wiLCJhIjoiY2w5YmlkazFiMDlkbjN2cGwweXk2azY4YyJ9.wcW5J6fU8OJ0jcN7VAqaEA';
        const center = [100.49271840103339, 13.751693205471755];
     map.current = new mapboxgl.Map({
        container: 'map',
        zoom: 16,
        center: center,
        pitch: 60,
        // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
        style: 'mapbox://styles/mapbox/light-v10',//style: 'mapbox://styles/mapbox/streets-v11'
        antialias: true

    });


    map.current.on('load', () => {
        const layers = map.current.getStyle().layers;
        const labelLayerId = layers.find(
        (layer) => layer.type === 'symbol' && layer.layout['text-field']
        ).id;

        map.current.addLayer(
            {
                'id': 'add-3d-buildings',
                'source': 'composite',
                'source-layer': 'building',
                'filter': ['==', 'extrude', 'true'],
                'type': 'fill-extrusion',
                'minzoom': 15,
                'paint': {
                'fill-extrusion-color': '#aaa',

                // Use an 'interpolate' expression to
                // add a smooth transition effect to
                // the buildings as the user zooms in.
                'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height']
                ],
                'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height']
                ],
                'fill-extrusion-opacity': 0.6
                }
            },
            labelLayerId
            );
        })


        // Initialize the geolocate control.
    if (!geolocate.current) createGeolocate()
    // Add the control to the map.
    map.current.addControl(geolocate.current);
    // Set an event listener that fires
    // when a geolocate event occurs.
    geolocate.current.on('geolocate', data => {
        setTrackLocationUI(o => ({...o, label: 'STOP', color: 'error'}))
        // console.log(data);
        setCoords(data.coords) //this.coords.longitude
        setLastDetected(Date.now())

        // console.log('location detected')
    });
    geolocate.current.on('trackuserlocationend', function() {
        setTrackLocationUI(o => ({ ...o, label: 'START', color: 'success', sent: 0}))
        // console.log('STOPPED')
    });

    geolocate.current.on('trackuserlocationstart', function() {
        console.log('A trackuserlocationstart event has occurred.')
        });

    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
   });

   useEffect(() => {


    console.log(lastDetected - lastSaved);
    if (lastDetected - lastSaved < updateInterval) return
    let { longitude,  latitude } = coords
    let lastCoords = localStorage.getItem('coords')
    // console.log(JSON.parse(lastCoords));
    if (lastCoords && lastCoords !== "{}") {

        let keys = Object.entries(lastCoords).map(([k, v]) => coords[k] === v ? k : '')
        if (!['latitude', 'longitude'].every(v => keys.includes(v))) {
            // console.log('location changed');

            let { longitude: lastLon,  latitude: lastLat } = JSON.parse(lastCoords)

            if (lastLon && lastLat && latitude && longitude) {
                var from = turf.point([lastLon, lastLat]);
                // console.log(from);
                var to = turf.point([longitude, latitude]);
                var options = {units: 'meters'};

                var distance = turf.distance(from, to, options);

                saveLocation(coords)
                setTrackLocationUI(o => ({...o, distance: Math.round(distance).toFixed(2)}))
                // console.log('distance', distance);
            }

            localStorage.setItem('coords', JSON.stringify({longitude, latitude}))
        }

    } else {

        // console.log('new location');
        saveLocation(coords)
        localStorage.setItem('coords', JSON.stringify({longitude, latitude}))

    }


    async function saveLocation() {
        var md5 = require('md5');

        //TODO:- add context here
        let url = "https://disasterrelief.upthehill-tothe.top/disaster/models/linePositionHostory.php"
        let data = {
            user: "admin",
            pass:  md5("admin"),
            mode: "add",
            userId: profile.userId,
            lon: coords.longitude,
            lat: coords.latitude,
            url: url
        }


        const params = new URLSearchParams()
        Object.entries(data).map(([k, v]) => params.append(k, v))

        let http = new HTTPService();
                    // setLog('requesting save at: ' + Date.now())

                    setLastSaved(lastDetected)
                    await http.crud(url, params).then(response => {

                        console.log(response);

                        // setLog('success')

                        if (response.ok) {

                            var DateTime = luxon.DateTime
                            let time = DateTime.local().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
                            console.log('Done saving at ' + time);

                            setTrackLocationUI(o => ({...o, sent: o.sent + 1}))

                        }

                    })
                    .catch(error => console.log(error))


    }
    // async function saveLocation() {
    //     var md5 = require('md5');

    //     //TODO:- add context here
    //     let url = "https://disasterrelief.upthehill-tothe.top/disaster/models/linePositionHostory.php"
    //     let data = {
    //         user: "admin",
    //         pass:  md5("admin"),
    //         mode: "add",
    //         userId: profile.userId,
    //         lon: coords.longitude,
    //         lat: coords.latitude,
    //         url: url
    //     }


    //     const params = new URLSearchParams()
    //     Object.entries(data).map(([k, v]) => params.append(k, v))

    //     crud(data).then(response => {

    //         // console.log(response);

    //         // setLog(JSON.stringify(response))
    //         // setLog('New location save at ' + Date.now())

    //         if (response.data) {
    //             var DateTime = luxon.DateTime
    //             let time = DateTime.local().toLocaleString(DateTime.DATETIME_SHORT)
    //             console.log('Done saving at ' + time);

    //             setTrackLocationUI(o => ({...o, sent: o.sent + 1}))
    //             // window.alert('saved at ' + time)
    //             // setPosition(position)
    //             // setRows(rows => [{
    //             //     id: time,
    //             //     latitude: position.coords.latitude,
    //             //     longitude: position.coords.longitude
    //             // },...rows])

    //             // console.log(position);
    //         }
    //     }).catch(error => console.log(error))
    // }


   }, [lastSaved, coords, profile, updateInterval, lastDetected]);

   const toggleUserLocation = () => {


    if (geolocate && geolocate.current) {

        if (!geolocate.current.options.trackUserLocation) {
            createGeolocate()
        } else {
            geolocate.current.trigger()
        }
        // console.log(geolocate.current);
        //     if (geolocate && geolocate.current && geolocate.current.options.trackUserLocation) geolocate.current.finish()
    } else {
        createGeolocate()
    }
   }

   function toggleSidebar(id) {
        const elem = document.getElementById(id);
        // Add or remove the 'collapsed' CSS class from the sidebar element.
        // Returns boolean "true" or "false" whether 'collapsed' is in the class list.
        const collapsed = elem.classList.toggle('collapsed');
        const padding = {};
        // 'id' is 'right' or 'left'. When run at start, this object looks like: '{left: 300}';
        padding[id] = collapsed ? 0 : 300; // 0 if collapsed, 300 px if not. This matches the width of the sidebars in the .sidebar CSS class.
        // Use `map.easeTo()` with a padding option to adjust the map's center accounting for the position of sidebars.
            map.current.easeTo({
        padding: padding,
        duration: 1000 // In ms. This matches the CSS transition duration property.
        });
        setToggleSymbol(collapsed ? "→" : "←")
    }

    function IntervalSlider(){

        const handleChange = (event, newValue) => {
            if (typeof newValue === 'number') {
            setValue(newValue);
            setUpdateInterval(newValue * 5 * 1000)
            }
        };
        const marks = [5, 10, 30, 60, 90, 120, 180, 300].map(s => ({
            value: s
          }));

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='mute' id="interval-slider" gutterBottom>
        ความถี่ในการบันทึก ทุก {valueLabelFormat(calculateValue(value))}
      </Typography>
      <Slider
        value={value}
        marks={marks}
        min={1}
        step={1}
        max={60}
        defaultValue={1}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="interval-slider"
      />
    </Box>
  );
    }

  return (
    <React.Fragment>
      <CssBaseline />

      <div id="map" ></div>
        <div id="left" className="sidebar flex-center left collapsed">
            <div className="sidebar-content rounded-rect flex-center">
                <div className="map-overlay">
                    <Container maxWidth="md">
                        <Box sx={{ flexGrow: 1, }}>
                            <Stack direction={{ xs:'row', sm:'column'}}>
                            <Grid container spacing={{ xs: 1, md:2}} padding={{ xs: 1, md:2}}>
                                <Grid item  xs={12}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height={{xs: 80, sm:180}}
                                        image={profile.pictureUrl}
                                        alt={profile.displayName}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                        {profile.displayName}
                                        </Typography>
                                    </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item>
                                    <IntervalSlider />
                                </Grid>
                            </Grid>
                            <Grid container spacing={{ xs: 1, md:2}} padding={{ xs: 1, md:2}}>
                                <Grid item>
                                    <TextField fullWidth size="small" disabled color="success" label="Latitude:" id="Latitude" value={coords.latitude || 0} />
                                </Grid>
                                <Grid item>
                                    <TextField fullWidth size="small" disabled color="success" label="Longitude:" id="Longitude" value={coords.longitude || 0} />
                                </Grid>
                                <Grid item>
                                    <TextField fullWidth size="small" disabled color="warning" label="คลาดเคลื่อน" id="accuracy" value={coords.accuracy ? (coords.accuracy + ' เมตร') : ''} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='mute'>{trackLocationUI.distance > 0 ? `คุณเคลื่อนที่ ${trackLocationUI.distance} เมตร` : ''}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Item>บันทึกแล้ว {trackLocationUI.sent} รายการ</Item>
                                </Grid>
                            </Grid>
                            </Stack>
                        </Box>
                    </Container>
                </div>
                <div className="sidebar-toggle rounded-rect left" onClick={event => { toggleSidebar('left'); }}>
                {toggleSymbol}
                </div>

                <div className="sidebar-button rounded-rect left">
                    <Button sx={{ height: '100%'}} variant="contained" color={trackLocationUI.color} onClick={() => toggleUserLocation()}>{trackLocationUI.label}</Button>
                </div>


            </div>
        </div>



    </React.Fragment>
  );
}
