import React, {useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
// import HTTPService from "../services/httpService"
import { Button, Typography } from "@material-ui/core";
import { fetch } from "whatwg-fetch";
import HTTPService from "../services/httpService";
// import axios from "axios";

import copy from 'copy-text-to-clipboard';
const luxon = require('luxon')
luxon.Settings.defaultLocale = 'th';
luxon.Settings.defaultOutputCalendar = 'buddhist';
const  columns = [
    {field: 'id', headerName: 'TIME', flex: 1},
    {field : 'latitude', headerName: 'Lat',  flex: 1},
    {field: 'longitude', headerName: 'Lon',  flex: 1}
]
const os = require('os');
console.log(`probably recheck if os.hostname() != 'localhost' currently:${os.hostname()}`);


export default function LandingPage() {
    var [position, setPosition] = useState(null)
    var [rows, setRows] = useState([])
    var [log, setLog] = useState('');    

    useEffect(() => {

        initialLocation()

        let id;
        if (navigator.geolocation) {
            id = navigator.geolocation.watchPosition(async function (position) {

                // setLog('location logging started, latest position:' + (position ? position.coords ? `${position.coords.latitude}, ${position.coords.longitude}` : '' : ''))
                var md5 = require('md5');
                
                //TODO:- add context here
                let url = "https://disasterrelief.upthehill-tothe.top/disaster/models/linePosition"   
                let data = {
                    user: "admin",
                    pass:  md5("admin"),
                    mode: "add",
                    name: "name",
                    userId: '435345232f297a5732423543894a0e4a801fc3',
                    missionId: "1",
                    lon: position.coords.longitude,
                    lat: position.coords.latitude,
                    url: url
                }


                const params = new URLSearchParams()
                Object.entries(data).map(([k, v]) => params.append(k, v))
             
                // navigator.geolocation.clearWatch(id)

                if (os.hostname() !== 'localhost') {
                    await fetch("/crud", { //await fetch("https://203e-180-183-13-88.ap.ngrok.io/crud", {  // https://fba3-122-155-138-34.ap.ngrok.io/airsandbox-32146/us-central1/crud", { //
                        method: 'POST',
                        body: params,
                    }).then(response => {

                        console.log(response);

                        setLog(JSON.stringify(response))
                        // setLog('New location save at ' + Date.now())

                        if (response.ok) {
                            console.log('done saving at ' + Date.now());
                            var DateTime = luxon.DateTime
                            let time = DateTime.fromMillis(Number(position.timestamp)).toFormat("HH:mm:ss, dLLL")
                            setPosition(position)
                            setRows(rows => [{
                                id: time,
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            },...rows])

                            // console.log(position);
                        }
                    }).catch(error => console.log(error))                    

                } else {

                    let http = new HTTPService();
                    // setLog('requesting save at: ' + Date.now()) 
                    await http.crud(url, params).then(response => {
                        
                        console.log(response);
                        
                        // setLog('success')

                        if (response.ok) {
                            var DateTime = luxon.DateTime
                            let time = DateTime.fromMillis(Number(position.timestamp)).toFormat("HH:mm:ss, dLLL")
                            setPosition(position)
                            setRows(rows => [{
                                id: time,
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            },...rows])

                            // console.log(position);
                        }

                    }) 
                    .catch(err => {
                        setLog(`ERROR: ${err}`)
                    })
                }
                
                // navigator.geolocation.clearWatch(id)


            }, () => {}, {enableHighAccuracy: true});
        }

        return () => {
            navigator.geolocation.clearWatch(id)
        };
    }, []);


    function initialLocation() {

        if ("geolocation" in navigator) {
            console.log("Available");
        } else {
            console.log("Not Available");
        }
    }

    function copyButton(text) {
        return (<>
            <Button variant="outlined" size="small" onClick={()=> copy(text)} >
          COPY
        </Button>
        </>)
    }

    return (<>

        {log}

        
        
        {/* <p>{position ? position.coords ? `${position.coords.latitude}, ${position.coords.longitude}` : '' : ''}</p> */}
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={4}
                rowsPerPageOptions={[5]}
                 />
        </div>


    </>)
}


