import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


import LandingPage from './landingPage/landingPage';
import LiveLocation from './livelocation/livelocation';

export const NGROK_URL = 'https://5d4c-2001-fb1-5d-6a55-12f-2b84-2dd9-8c9e.ap.ngrok.io'
export const MAPTILER_KEY = 'QjDDzVySbfWrOKbmF3Na'
function App() {
  return (<Router>
    <div>
      
      {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
      <Routes>
        <Route exact path="/" element={<LiveLocation/>} />
        <Route path="/landingpage" element={<LandingPage/>} />
        <Route path="/livelocation"  element={<LiveLocation/>} />
      </Routes>
    </div>
  </Router>);
}

export default App;
