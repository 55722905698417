import {fetch} from 'whatwg-fetch'

// const axios = require('axios').default

class HTTPService {

    crudFirebase = (url, params) => {

        return new Promise(async(resolve, reject) => {
            await
            fetch("https://fba3-122-155-138-34.ap.ngrok.io/airsandbox-32146/us-central1/crud", {
                method: 'POST',
                body: params
            }).then(response => {
                // console.log(response);
                console.log('done saving at ' + Date.now());
                resolve(response)
            }).catch(error => reject(error))

        })
    }

    crudproxy = (url, params) => {

        return new Promise(async(resolve, reject) => {
            await
            fetch("/crud", {
                method: 'POST',
                body: params
            }).then(response => {
                // console.log(response);
                console.log('done saving through proxy at ' + Date.now());
                resolve(response)
            }).catch(error => reject(error))

        })
    }

    crud = (url, params) => {

        return new Promise(async(resolve, reject) => {
            await
            fetch(url, {
                method: 'POST',
                body: params,
                mode: 'cors'
            }).then(response => {
                console.log('http response', response);
                console.log('http service done saving at ' + Date.now());
                resolve(response)
            }).catch(error => reject(error))

        })
    }

    

    // crudLinePosition = (data) => {

    //     let url = "https://disasterrelief.upthehill-tothe.top/disaster/models/linePosition"

    //     return new Promise(async(resolve, reject) => {
    //         await axios.post(url, data)
    //         .then(response => {
    //             let data = response.data;
    //             console.log(data);
    //             resolve(data);
    //         })
    //         .catch( error => {
    //             reject(error);
    //         })
    //     })

    // }

    // crudAccount = (data) => {

    //     let url = "https://disasterrelief.upthehill-tothe.top/disaster/models/lineAccounts"

    //     return new Promise(async(resolve, reject) => {
    //         await axios.post(url, data)
    //         .then(response => {
    //             let data = response.data;
    //             console.log(data);
    //             resolve(data);
    //         })
    //         .catch( error => {
    //             reject(error);
    //         })
    //     })

    // }


}
export default HTTPService